:root {
	--white: #fff;
	--black: #050017;
	--blue: #1698f3;
	--blue2: rgba(0, 123, 255, 0.07);
	--green: #379a5f;
	--red: #f00000;
	--greyDark: #d3d3d3;
	--greyDark2: #808080;
	--greyLight: #e8e9f4;
	--greyLanguagePicker: #bababa;
	--greyHoverLogoDropdown: #f6f6f6;
	--defaultBoxShadow: 0px 4px 20px rgba(4, 28, 114, 0.1);

	--defaultFontFamily: 'Montserrat', sans-serif;
	--fontSize: 16px;
	--container: 1024px;
	--containerPaddingX: 15px;
	--asideWidth: 226px;
	--asideHeight: 396px;
	--indentFromAside: calc(var(--asideWidth) + 61px);

	--zIndexEditForm: 99;
	--zIndexEditFormBody: 98;
	--zIndexAside: 100;
	--zIndexShadowWrapper: 100;
	--zIndexHeader: 100;
	--zIndexLogoDropdown: 101;
	--zIndexButtonClose: 102;
}
