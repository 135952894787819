.document-preview {
	position: relative;
	z-index: 1;
	min-height: 93px;

	&__body {
		height: 100%;
		display: flex;
		padding: 16px;
	}

	&__icon {
		flex: 0 0 auto;
		margin-right: 24px;
	}

	&__info {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	&__title {
		font-weight: 500;
		margin-bottom: 0;
		@include textTruncateLine(1);
	}

	&__button {
		color: var(--green);
		margin-right: 12px;
	}
}
