.select {
	&__input {
		border-radius: 4px;
		z-index: 10;
	}

	&--error {
		* {
			border: none !important;
		}

		.select__input {
			border: 1px solid var(--red) !important;
		}
	}
}
