.file-input {
	--fileInputMarginBottom: 32px;
	position: relative;
	margin-bottom: var(--fileInputMarginBottom);

	&__label {
		margin-bottom: 0;
	}

	&__button-link {
		margin-bottom: 0;
	}

	> .input-error {
		top: auto;
		bottom: calc(-1 * var(--fileInputMarginBottom) + 15px);
	}
}
