.list-with-pagination {
	display: flex;
	margin-bottom: 32px;
	gap: 24px;

	&--row {
		flex-direction: row;
		flex-wrap: wrap;
	}

	&--column {
		flex-direction: column;
	}
}
