.header {
	position: relative;
	width: 100%;
	padding-top: 36px;
	z-index: var(--zIndexHeader);
	margin-bottom: 32px;

	&__body {
		display: flex;
		justify-content: space-between;
		align-items: center;
		@extend %contentIndents
	}
}
