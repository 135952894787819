.button-link {
	display: inline-block;
	position: relative;
	line-height: 1;
	color: var(--blue);
	user-select: none;
	@extend %textUnderline;

	&--login {
		font-size: 14px;
		font-weight: 500;
		@extend %textUnderlineReverse;
	}

	&--big {
		font-size: 22px;
		font-weight: 500;

		&::before {
			content: '+';
			display: inline-block;
			margin-right: 5px;
		}

		&.active::before {
			content: '-';
		}
	}

	&--small {
		font-size: 14px;
		font-weight: 500;
	}
}
