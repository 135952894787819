.editable-image {
	position: relative;
	width: auto;
	overflow: hidden;

	&::before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: var(--black);
		opacity: 0.2;
	}

	img {
		z-index: -1;
	}

	&__leftBottomCorner,
	&__rightBottomCorner {
		color: red;
		font-size: 14px;
		position: absolute;
		bottom: 0;
		z-index: 0;

		margin: 5px;
	}

	&__leftBottomCorner {
		left: 0;
	}

	&__rightBottomCorner {
		right: 0;
	}

	&--no-image {
		&::before {
			background-color: transparent;
		}

		.editable-image__button {
			color: var(--black);

			&::before {
				background-image: url(../img/cameraBlack.svg);
			}
		}
	}

	&__svg {
		margin: 10px;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		cursor: pointer;
	}

	&__button {
		--buttonImageWidth: 32px;
		--buttonImagePaddingBottom: 11px;
		position: absolute;
		top: calc(
			50% + (var(--buttonImageWidth) / 2) +
				var(--buttonImagePaddingBottom) / 2
		);
		left: 50%;
		transform: translate(-50%, -50%);
		color: var(--white);
		font-size: 14px;
		font-weight: 600;
		z-index: 0;

		&::before {
			content: '';
			display: block;
			position: absolute;
			top: calc(
				-1 * var(--buttonImageWidth) - var(--buttonImagePaddingBottom)
			);
			left: 50%;
			transform: translateX(-50%);
			width: var(--buttonImageWidth);
			height: 25px;
			background-image: url(../img/camera.svg);
		}
	}

	&__add-image {
		width: 200px;
		height: 200px;
		background-color: black;
	}
}
