.label {
	display: inline-block;
	width: 100%;
	line-height: 1;
	margin-bottom: 5px;

	&--bold {
		font-size: 16px;
		font-weight: 600;
		color: var(--black);
		transform: translateY(-4px);
	}

	&--light {
		font-size: 12px;
		color: var(--greyDark2);
	}
}
