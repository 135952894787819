.pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	column-gap: 16px;
	margin-bottom: 48px;

	&__prev {
		margin-right: 8px;
	}

	&__num {
		color: var(--greyDark);

		&--current {
			font-size: 22px;
			color: var(--black);
		}
	}

	&__next {
		margin-left: 8px;
	}
}
