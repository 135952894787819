.button-close {
	--buttonCloseSize: 24px;

	display: inline-block;
	position: relative;
	width: var(--buttonCloseSize);
	height: var(--buttonCloseSize);
	transition: transform 0.3s ease;

	&::before,
	&::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) rotate(-45deg) scale(1);
		width: 100%;
		height: 4px;
		background-color: var(--greyLight);
	}

	&::after {
		transform: translate(-50%, -50%) rotate(45deg) scale(1);
	}

	&--right {
		position: absolute;
		top: 12px;
		right: 12px;
		z-index: var(--zIndexButtonClose);
	}
}
