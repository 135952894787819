.active {
    color: blue;
}

.lang {
    margin-right: 10px;
}

.editButton {
    cursor: pointer;
    text-decoration: underline;
    color: #379A5F;
}

span {
    word-break: break-all;
}
