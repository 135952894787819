.login {
	width: 100%;
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;

	&__body {
		padding: 32px 55px;
	}

	&__title {
		text-align: center;
		text-transform: uppercase;
		line-height: 1;
		margin-bottom: 42px;
	}

	&__button-link {
		margin-bottom: 16px;
	}
}
