.category-form {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, .5);
	backdrop-filter: blur(1px);
	z-index: var(--zIndexEditForm);

	&__body {
		position: absolute;
		top: 100px;
		left: calc(50% + 120px);
		transform: translateX(-50%);
		width: 513px;
		height: 417px;
		background-color: var(--white);
		border-radius: 4px;
		box-shadow: 0px 4px 20px rgba(4, 28, 114, 0.1);
		padding: 24px;
		z-index: var(--zIndexEditFormBody);

		.form-item {
			margin-bottom: 52px;

			&:nth-last-child(2) {
				margin-bottom: 32px;
			}
		}

		.label {
			transform: translateY(-7px);
		}
	}

	&__item {
		height: auto;
	}

	&__footer {
		display: flex;
		justify-content: flex-end;
		align-items: flex-start;
		column-gap: 24px;
	}

	&__button-delete {
		align-self: center;
		color: var(--red) !important;
		background: none;
	}
}
