.vacancy-preview {
	position: relative;
	z-index: 1;
	width: 100%;
	height: 68px;

	&__body {
		height: 100%;
		padding: 12px;
		display: flex;
		align-items: flex-start;
		overflow: hidden;
	}

	&__title {
		flex: 0 0 75%;
		font-weight: 500;
		padding-right: 16px;
		@include textTruncateLine(2);
	}

	&__control {
		flex: 0 0 25%;
		text-align: right;
	}

	&__button-edit {
		color: var(--green);
		margin-right: 12px;
	}
}
