* {
	padding: 0px;
	margin: 0px;
	border: 0px;
	-webkit-tap-highlight-color: transparent;
}

*,
*:before,
*:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

:focus,
:active {
	outline: none;
}

a:focus,
a:active {
	outline: none;
}

aside,
nav,
footer,
header,
section {
	display: block;
}

html {
	font-size: var(--fontSize);
}

body {
	font-family: var(--defaultFontFamily);
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.45;
	letter-spacing: 0.8px;
	color: var(--black);
	touch-action: pan-x pan-y;
}

html,
body {
	width: 100%;
	min-height: 100%;
}

input,
button,
textarea {
	font-family: var(--defaultFontFamily);
	font-size: 1rem;
}

input,
textarea {
	resize: none;
}

input::-ms-clear {
	display: none;
}

button {
	cursor: pointer;
	border: none;
	font-size: 1rem;
	background-color: transparent;
}

button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

a,
a:visited {
	text-decoration: none;
}

a:hover {
	text-decoration: none;
}

ul {
	margin: 0;
	padding: 0;
}

ul li {
	list-style: none;
}

img {
	vertical-align: top;
	border-style: none;
	border: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: inherit;
	font-size: inherit;
	cursor: default;
}

address {
	font-style: normal;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
	display: none;
}

.container {
	max-width: var(--container);
	height: 100%;
	margin: 0 auto !important;
	padding: 0;
}

.wrapper {
	position: relative;
	overflow: hidden;

	&__background.top-left,
	&__background.bottom-right {
		display: none;
	}

	&--background {
		.wrapper__background.top-left {
			display: block;
			top: 0;
			left: 0;
			transform: translate(-45%, -37%);
		}

		.wrapper__background.bottom-right {
			display: block;
			bottom: 0;
			right: 0;
			transform: translate(75%, 27%);
		}
	}
}
