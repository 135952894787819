.document-page {
	margin-right: 40px;

	&__create-form {
		padding: 24px;
		margin-top: 24px;
	}

	&__title {
		font-size: 24px;
		font-weight: 500;
		line-height: 35px;
		text-decoration: underline;
		text-decoration-skip-ink: none;
		margin-bottom: 12px;
	}

	&__download-file {
		text-wrap: nowrap;
		max-width: 650px;
		overflow: hidden;
		text-overflow: ellipsis;
		&::before {
			content: none;
		}
	}
}
