.language-picker {
	display: flex;
	column-gap: 12px;
	margin: 0;
	padding: 0;

	&__button {
		display: inline-block;
		font-weight: 400;
		color: var(--greyLanguagePicker);
		transition: color .15s ease;

		&.active {
			font-weight: 600;
			color: var(--blue);
		}

		&:hover {
			color: var(--blue);
		}
	}
}
