.category-preview-body {
	position: relative;
	z-index: 1;
	height: 100%;

	&__title {
		font-size: 14px;
		@include textTruncateLine(1);
	}

	&__button-edit {
		color: var(--green);
	}

	&__control {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	&__button-edit {
		align-self: center;
	}

	&__loader {
		position: absolute;
		left: 50%;
		top: 50%;
	}
}
