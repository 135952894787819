.shadow-wrapper {
	position: relative;
	max-width: var(--widthWrapper);
	min-height: var(--heightWrapper);
	background-color: var(--white);
	box-shadow: var(--defaultBoxShadow);
	z-index: var(--zIndexShadowWrapper);

	&--login {
		--widthWrapper: 406px;
		--heightWrapper: 190px;
		border-radius: 10px;
	}

	&--content {
		--widthWrapper: 100%;
		--heightWrapper: 50px;
		border-radius: 4px;
	}
}
