.input {
	--inputHeight: 40px;
	width: 100%;
	height: var(--inputHeight);
	box-sizing: border-box;
	border-radius: 4px;
	border: 1px solid var(--greyDark);
	padding: 0 16px;

	&[type=password] {
		font: small-caption;
		font-size: 21px;
		letter-spacing: 3px;
		padding-top: 2px;
	}

	&--password {
		padding-right: 58px;
	}

	&--confirm {
		width: 45%;
	}

	&--code {
		width: 50%;
		margin-right: auto;
	}

	&--error {
		border: 1px solid var(--red);
	}
}
