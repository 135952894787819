.reset-password {

	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 32px 32px 16px 32px;
		border-bottom: 1px solid var(--greyDark);
	}

	&__title {
		margin-bottom: 0;
	}

	&__body {
		padding: 24px 36px 32px 36px;
	}

	.form-item {
		margin-bottom: 38px;
	}

	.button:not(:last-child) {
		margin-bottom: 16px;
	}
}
