.input-error {
	display: inline-block;
	position: absolute;
	left: 1px;
	top: 68px;
	font-size: 12px;
	font-weight: 500;
	line-height: 1.2;
	color: var(--red);

	&--table-field {
		top: 30px;
	}

	&--geo-field {
		top: 40px;
		width: 120%;
	}
}
