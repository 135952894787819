.vacancy-form {
	margin-top: 24px;

	&__body {
		padding: 24px;
	}

	&__footer {
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}
}
