%contentIndents {
	margin-left: var(--indentFromAside);
	padding-right: 40px;
}

%textUnderline {
	--widthLine: 100%;
	padding-bottom: 2px;
	background:
		linear-gradient(currentColor 0 0)
		0 100% /var(--widthLine, 0) 1px
		no-repeat;

	&:hover {
		--widthLine: 0;
	}
}

%textUnderlineReverse {
	--widthLine: 0%;
	padding-bottom: 2px;
	background:
		linear-gradient(currentColor 0 0)
		0 100% /var(--widthLine, 0) 1px
		no-repeat;

	&:hover {
		--widthLine: 100%;
	}
}
