.project-list-item {
	position: relative;
	z-index: 1;
	flex: 0 0 290px;
	margin-right: 12px;
	height: 301px;

	&__body {
		padding: 12px 8px;
		overflow: hidden;
		height: 100%;
	}

	&__title {
		height: 38.5px;
		font-weight: 500;
		margin: 0 0 8px 0;
		@include textTruncateLine(2);
	}

	&__image {
		position: relative;
		width: 278px;
		height: 192px;
		overflow: hidden;
		border-radius: 4px;
		margin-bottom: 8px;
		background-image: url(../img/no-image.png);
		z-index: -3;

		img {
			position: absolute;
			min-width: 100%;
			min-height: 100%;
			top: 0;
			left: 0;
			object-fit: cover;
		}
	}

	&__footer {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		align-items: flex-start;
	}

	&__button-edit {
		margin-right: 8px;
		align-self: center;
		color: var(--green);
	}
}
