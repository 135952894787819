.table-input {
	margin-bottom: 32px;

	&__body {
		display: flex;
		align-items: center;
		border: 1px solid transparent;
		margin: 0 -20px;
		padding: 0 20px;
	}

	.form-item {
		flex: 0 0 46%;
		margin-bottom: 0;
		margin-right: 20px;

		&:nth-last-child(2) {
			margin-right: 12px;
		}
	}

	&__field {
		height: 29px !important;
	}

	&__button-trash {
		flex: 0 0 13px;
	}

	&__button-add {
		font-weight: 500 !important;
		font-size: 14px !important;

		&-error {
			top: 18px !important;
			width: 150%;
			text-align: left;
		}
	}

	.drag-container {
		padding: 0 0 0 0 !important;
		border: none !important;
		margin: 0 0 16px 0 !important;
	}
}
