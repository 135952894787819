.category-preview {
	position: relative;
	z-index: 1;
	flex: 0 0 48%;
	height: 61px;

	&__body {
		padding: 8px 12px;
		height: 100%;
	}

	&.countries {
		flex: 0 0 31%;
	}
}
