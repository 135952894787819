.aside {
	--borderRadius: 60px;

	position: fixed;
	top: 0;
	width: var(--asideWidth);
	min-height: var(--asideHeight);
	background-color: var(--blue2);
	border-top-right-radius: var(--borderRadius);
	border-bottom-left-radius: var(--borderRadius);
	z-index: var(--zIndexAside);

	&__body {
		height: 100%;
		padding: 42px 15px 42px 56px;
	}

	&__logo-dropdown {
		margin-bottom: 24px;
	}
}
