.form-item {
	width: 100%;
	margin-bottom: 36px;
	position: relative;

	.show-password {
		position: absolute;
		bottom: 8px;
		right: 20px;
	}
}
