.arrow {
	display: inline-block;
	width: 30px;
	height: 8px;

	&--grey {
		background: url(../img/arrowGrey.svg) transparent no-repeat 0 / cover;
	}

	&--black {
		background: url(../img/arrowBlack.svg) transparent no-repeat 0 / cover;
	}

	&--left {
		transform: rotate(0);
	}

	&--right {
		transform: rotate(180deg);
	}
}
