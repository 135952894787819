.news-form {

	&__header {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
	}

	&__input-title {
		width: 368px;
		margin-right: 32px;
	}

	&__footer {
		display: flex;
		width: 100%;
		justify-content: flex-end;
	}
}
