.geo-input {
	width: 100%;

	&__header {
		display: flex;
		margin-bottom: 16px;
	}

	&__label {
		display: inline-block;
		width: auto !important;
		height: auto !important;
		margin: 9px 16px 0 0 !important;
		padding: 0 0 0 0;
	}

	&__wrapper {
		display: flex;
		row-gap: 16px;
		flex-direction: column;
	}

	&__latitude,
	&__longitude {
		display: flex;
		align-items: center;
		margin: 0 0 0 0;

		span {
			display: inline-block;
			margin-right: 24px;
		}

		input {
			padding: 0 0 0 12px;
		}
	}

	.form-item {
		width: 129px;
		margin: 0 0 0 0;
	}
}
