.switch-input {
	display: flex;
	justify-content: flex-start;
	align-items: center;

	z-index: 10;

	&__label {
		display: inline-block;
		width: auto !important;
		height: auto !important;
		margin: 6px 16px 0 0 !important;
		padding: 0 0 0 0;
	}

	&__control {
		flex: 0 0 auto;
	}
}
