.editor-input {
	position: relative;
	margin-bottom: 32px;

	.input-error {
		top: auto;
		bottom: -18px;
	}

	&--error {
		.tox {
			border: 1px solid var(--red) !important;
		}
	}
}
