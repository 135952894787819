.button {
	--buttonHeight: 41px;

	display: inline-block;
	width: auto;
	min-width: 20px;
	min-height: var(--buttonHeight);
	padding: 0 12px;
	margin: 0;
	font-weight: 500;
	color: var(--white);
	text-align: center;
	border: 1px solid transparent;
	background-color: var(--blue);
	user-select: none;
	border-radius: 4px;
	transition: opacity 0.15s ease;

	&:disabled {
		background-color: var(--greyDark);
	}

	&:hover:not(&:disabled) {
		opacity: 0.8;
	}

	&--full-length {
		width: 100%;
	}
}
