.navigation {
	display: flex;
	flex-direction: column;
	row-gap: 24px;

	&__button {
		font-weight: 500;
		color: var(--black);
		transition: color .15s ease;

		&.active {
			font-weight: 600;
			color: var(--blue);
		}

		&:hover {
			color: var(--blue);
		}
	}
}
