.image-input {
	position: relative;

	label {
		cursor: pointer;
	}

	.input-error {
		top: auto;
		bottom: -24px;
	}

	&__wrapper {
		position: relative;
	}

	&__change {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
