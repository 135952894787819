.react-datepicker-popper {
	z-index: 100;
}

.react-datepicker-wrapper {
	width: 160px;
}

.react-datepicker__input-container {
	--inputHeight: 40px;

	position: relative;
	&::after {
		content: '';
		display: inline-block;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 18px;
		width: 15px;
		height: 8px;
		font-size: 24px;
		background: url(../../img/arrowDatePicker.svg) transparent no-repeat 0 / 100%;
	}
	input {
		width: 151px;
		max-width: auto;
		box-sizing: border-box;
		border-radius: 4px;
		border: 1px solid var(--greyDark);
		font-size: 16px;
		letter-spacing: 1px;
		line-height: 37px;
		padding-left: 12px;
		margin: 0;
		height: var(--inputHeight);
		cursor: pointer;
	}
}

.react-datepicker__day--selected {
	background-color: var(--blue);
}
