.title {
	font-weight: 600;
	color: var(--black);

	&--large {
		font-size: 22px;
	}

	&--middle {
		font-size: 18px;
	}

	&--small {
		font-size: 16px;
	}

	&--vertical-indent {
		margin: 32px 0 24px 0;
	}
}
