.news-preview {
	position: relative;
	z-index: 1;
	min-height: 156px;

	&__body {
		height: 100%;
		display: flex;
		padding: 16px;
	}

	&__image {
		display: flex;
		flex: 0 0 176px;
		height: 124px;
		margin-right: 24px;
		overflow: hidden;
		border-radius: 4px;

		img {
			object-fit: cover;
		}
	}

	&__content {
		max-height: 400px;
		overflow: auto;

		&::-webkit-scrollbar {
			width: 3px;
			height: 0;
		}

		&::-webkit-scrollbar-track {
			background-color: #e4e4e4;
			border-radius: 100px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #181c6c;
			box-shadow: inset 2px 2px 3px 0 rgba(0, 123, 255, 0.5);
		}

		img,
		iframe {
			width: 31%;
			height: 31%;
			object-fit: cover;

			margin-right: 10px;
			margin-bottom: 5px;
		}
	}

	&__info {
		flex: 1 1 100%;
		display: flex;
		flex-direction: column;
	}

	&__title {
		font-weight: 500;
		margin-bottom: 16px;
		@include textTruncateLine(1);
	}

	&__description {
		@include textTruncateLine(2);
	}

	&__button-edit {
		color: var(--green);
	}

	&__button-trash {
		padding: 0;
		margin: 0;
		color: var(--green);
	}

	&__control {
		flex: 0 0 auto;
		display: flex;
		column-gap: 12px;
		align-self: flex-end;
		margin-top: auto;
		align-items: center;
	}
}
